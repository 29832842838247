/* *{
   margin: 0%;
   height: 0%;
} */
.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40vh;
    margin-top: 30vh;
   
}

.form1 {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    background-color: #f4f4f4;
    padding: 30px;
    border: 1px solid #ccc;
    border-radius: 4px;

}

h1 {
    font-size: 24px;
    margin-bottom: 20px;
}

.leftview {
    width: 60%;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    justify-content: space-between;
   
}

.horizontal-row {
    border-top: .1px solid #e4e3e3;
    margin: 20px 0;
}

.bind-two {
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
    margin: 0.5rem 0rem;
}
.input-container {
    display: flex;
    flex-direction: column;
    text-align: start;
    align-items:flex-start;
    justify-content: flex-start;
    width: 70%;
    margin-right: 4rem;
}

.input-with-dollar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 4px;
    object-fit: contain;
    z-index: 1;
    margin-right: 2rem;
}

.dollar-sign {
    font-weight: bold;
    color: #000000;
    margin: 4.5px;
    text-align: center;
    position: absolute;
    z-index: 2;
    
}

.button-style {
    padding: 8px;
    background-color: #d8dbde;
    color: #5a5a5a;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    font-size: 12px;
    box-sizing: border-box;
    margin-right: 20px;
    padding-left: 1rem;
    width: 149%;
}

.select-one{
   width: 60%;
    margin-right: 1rem;
    width: calc(100% -50px); 
    margin-left: -1px;
    z-index: 3;
    text-align: start; 
}
.select-one1{
width: 220px;
}
.rate-show{
    font-size: 13.5px;
}
.button-style2{
    padding: 10px;
    background-color: #d8dbde;
    color: #5a5a5a;
    width: 115%;
    border-radius: 6px;
    height: 45px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center; 
}
.clr{
   color: rgb(39, 11, 220);
}
.clr1{
    color: rgb(16, 233, 70);
}
.tag-show{
    color: #000000;
}
.label-I{
   margin-left: 12px; 
}
@media screen and (max-width: 780px) {
     .home{
       margin-top: 17rem;
       justify-content: center;
       align-items: center;
       text-align: center;
       padding-top: 3rem;
    }
    .label-I{
        margin-left: 0px; 
     }
    .form1{
    width: 80%;
    }
   
   .bind-two{
    display: flex;
    flex-direction: column;
     margin: 10px;
 }
 .leftview{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
 }

 .bind-two1{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

 } 
 .select-one{
    width: 90%;
 }
.input-container
{ 
 padding-top: 2px;
}
.button-style{
    width: 170%;
}
   
.tax-rate{
    display: flex;
    flex-direction: row;
   text-align: center;
   align-items: center;
 }
.rate-show{
    font-size: 9.6px;
    padding-left: 1.1px;
}
.Tax-rate{
    font-size: 9.6px;
}
.button-style2{
    width: 134%;
}
}  