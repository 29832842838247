/* Normal state */
.button {
    background-color: white;
    color: black;
    border: 1px solid black;
    border-radius: 4px;
    cursor: pointer;
    padding: 8px;
    font-size: 10px;
    width: 180%;
  }
  
  .active1 {
    color: blue;
    border-color: blue;
  }
  .active2 {
    color: blue;
    border-color: blue;
  }
  
  .button-container{
    display: flex;
    width: 149%;
    flex-direction: row;
    justify-content: space-evenly;
   
  }
.btn{
    margin-top: 4px;
    font-size: 10px;
}
.lbutton{
    display: flex;
    flex-direction: column;
    font-size: 14px;
    align-items: flex-start;
    text-align: left;
   
}

.btn1{
  text-align: left;
  margin-left: -1.5rem;
  width: 144px;
}
.lbutton1{
  margin-right: -1rem;
}
.text{
  font-size: 12px;
}
@media screen and (max-width: 780px) {
 .btn1{
  width: 120px;
 } 
 .button-container{
  justify-content: space-evenly;
 }
 /* .btn3{
  margin-left: -2.4rem;
 } */
 .btn{
  align-items: flex-start;
  text-align: start;
  justify-content: flex-start;
  margin-right: -1rem;
 }
 .header{
  font-size: 25px;
 }
}

  