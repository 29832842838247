.box-container {
    display: flex;
    flex-direction: row;
    align-items:flex-start;
    justify-content: space-evenly;
}
  .label {
    font-size: 16px;
  }
  
  /* .box-content {
    font-size: 18px;
    margin-top: 5px;
  } */
  .box{
    text-align: start;
    width: 159%;
  }

   .button-style1{
    padding: 8px;
    background-color: #d8dbde;
    color: #5a5a5a;
    width: 90%;
    border-radius: 6px;
  } 
   @media screen and (max-width: 780px) {
    .box-container {
      display: flex;
      flex-direction: column;
      margin-left: .7rem;
  }
  .button-style1{
    width: 58%;
    align-items: center;
    justify-content: center;
  }
  
  }
   